<template>
  <el-container>
    <PrivacyProject />
  </el-container>
</template>

<script>
import { PrivacyProject } from '@/components/console/Index.vue';

export default {
  components: {
    PrivacyProject,
  },
};
</script>
